import React from 'react';

import './login-component.scss';
import PropTypes from 'prop-types';

import Logo from '../../../assets/images/logo-linx.png';

export class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.usernameRef = React.createRef();
  }

  handleChange(e) {
    this.props.form[e.target.id] = e.target.value;
    this.forceUpdate();
  }

  componentDidMount() {
    this.checkFocus();
  }
  checkFocus() {
    setTimeout(() => {
      if (this.props.loginAllowed)
        this.usernameRef.current.focus();
      else
        this.checkFocus();
    }, 500);
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  render() {
    const { form, handleConfirm, loginAllowed } = this.props;
    return (
      <div className="container-fuid page-login">
        <div className="row">
          <div className="col-xl-3 col-md-4 col-sm-12 bg-primary colum-height box-left d-flex colum-height-a align-items-center left">
            <img
              src="/assets/images/logo.svg"
              title="Logo Farmacloud"
              alt="Logo Farmacloud"
            />

            <p className="copyright"> © Linx - Todos direitos reservados </p>
          </div>
          <div className="col-md-8 col-xl-9 d-flex colum-height-b center align-items-center right">
            <div className="row">
              <div className="col-12 flex-center">
                <picture className="center-align logotipo text-center">
                  <img src={Logo} alt="Logo Linx" />
                </picture>
              </div>

              <div className="col-12 flex-center">
                <form className="form-login">
                  <div className="form-group">
                    <label htmlFor="username"> Usuário </label>
                    <input
                      autoFocus={loginAllowed}
                      ref={this.usernameRef}
                      id="username"
                      className="form-control"
                      onChange={this.handleChange}
                      value={form.username}
                      aria-describedby="usernameHelp"
                      placeholder="Digite seu usuário"
                      name="usuario"
                      maxLength={9}
                      disabled={loginAllowed ? "" : "disabled"}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password"> Senha </label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder="Digite sua senha"
                      value={form.password}
                      onChange={this.handleChange}
                      name="senha"
                      disabled={loginAllowed ? "" : "disabled"}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block"
                    onClick={handleConfirm}
                    disabled={loginAllowed ? "" : "disabled"}
                  >
                    Conectar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  form: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default LoginComponent;

import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showCard } from '../../redux/actions/routerAction';
import {
  CUSTOMER,
  CUSTOMER_REGISTRATION,
  shouldShow,
} from '../../redux/actions/views';
import {
  clearCustomer,
  updateCustomer,
} from '../../redux/modules/customerRegistration/duck';
import { showMessageAction } from '../../redux/modules/message/duck';
import AdministrativeService from '../../services/AdministrativeService';
import CustomerService from '../../services/customerService';
import SaleService from '../../services/saleService';
import { CustomerRegistration } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import SearchCepContainer from '../search-cep';

export class CustomerRegistrationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.saleService = new SaleService();
    this.customerService = new CustomerService();
    this.props.MessageBus.Subscribe(
      'pos.customer.showCustomerRegistration',
      this.handleShow
    );
    this.props.MessageBus.Subscribe(
      'pos.customer.customerRegistred',
      this.handleCustomerRegistred
    );

    this.props.MessageBus.Subscribe(
      'pos.administrative.getCepReturn',
      this.handleCepReturn
    );

    this.state = {
      showSearchCep: false,
      data: {},
      cepCallback: false,
      isKeyDownEnter: false
    };
  }

  handleCustomerRegistred = (ch, payload) => {
    this.saleService.notifySelectCustomer(payload.id, payload.referenceId);
    this.handleClose();
  };

  handleShow = (ch, payload) => {
    this.props.showCustomerRegistration(payload);
  };

  handleChange = (name, value) => {
    let formData = { ...this.props.data };
    formData[name] = value;
    this.props.update({
      ...formData
    });
    this.setState({ cepCallback: false });
  }

  handleConfirmSave = (data) => {
    this.customerService.customerRegistration(data);
  };

  handleIdentifyCepClick = (currentValues) => {
    this.props.update(currentValues);
    this.setState({ showSearchCep: true });
  };

  handleClose = () => {
    this.props.showCustomer();
  };

  handleSearchCep = (event) => {
    if (this.state.isKeyDownEnter) {
      this.setState({ isKeyDownEnter: false });
    } else {
      const cep = event.target.value.replace(/[^0-9]+/g, '');
      if (cep.length !== 8) return;
      new AdministrativeService().getCep(cep);
    }
  };

  handleKeyDownZipCode = (e) => {
    if (e.key === 'Enter') {
      this.setState({ isKeyDownEnter: true });
      e.preventDefault();
      this.handleSearchCep(e);
    }
  };

  handleCepReturn = (ch, payload) => {
    this.props.update({
      ...this.state.data,
      street: payload.rua,
      city: payload.cidade,
      neighborhood: payload.bairro,
      state: payload.uf
    });
    this.setState({ cepCallback: true });
  };

  handleSearchCepClose = () => {
    this.setState({ showSearchCep: false, cepCallback: false });
  }

  handleSelectCep = (payload) => {
    this.setState({ showSearchCep: false });
    this.props.update({
      street: payload.rua,
      neighborhood: payload.bairro,
      city: payload.cidade,
      state: payload.uf,
      zipCode: payload.cep
    });
    this.setState({ cepCallback: true });
  };

  render() {
    const { show, data } = this.props;
    const { showSearchCep } = this.state;
    return showSearchCep ? (
      <SearchCepContainer
        show={showSearchCep}
        onClose={this.handleSearchCepClose}
        onSelect={this.handleSelectCep}
      />
    ) : (
      show && (
        <CustomerRegistration
          data={data}
          handleClose={this.handleClose}
          handleChange={this.handleChange}
          handleConfirmSave={this.handleConfirmSave}
          onIdentifyCepClick={this.handleIdentifyCepClick}
          handleBlurZipCode={this.handleSearchCep}
          handleKeyDownZipCode={this.handleKeyDownZipCode}
          cepCallback={this.state.cepCallback}
        />
      )
    );
  }
}

CustomerRegistrationContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  showCustomerRegistration: PropTypes.func.isRequired,
  showCustomer: PropTypes.func.isRequired,
  MessageBus: PropTypes.shape({
    Subscribe: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({
    street: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  show: shouldShow(state, CUSTOMER_REGISTRATION),
  data: state.customerRegistration,
});

const mapDispatchToProps = (dispatch) => ({
  showCustomerRegistration: (payload) => {
    dispatch(updateCustomer(payload));
    dispatch(showCard(CUSTOMER_REGISTRATION, null, true));
  },
  showCustomer: () => {
    dispatch(clearCustomer());
    dispatch(showCard(CUSTOMER, {
      source: "sale",
      query: ''
    }));
  },
  update: (payload) => dispatch(updateCustomer(payload)),
  showMessage: (payload) => dispatch(showMessageAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CustomerRegistrationContainer)));
